<template>
  <div class="account-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
    >
      <!-- <el-table-column type="index" label="ID" align="center"></el-table-column> -->
      <el-table-column
        prop="tempId"
        label="ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="productPlanName" label="方案" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.productPlanName || "无" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="channelName"
        label="渠道"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="roleName" label="决策建议" align="center"></el-table-column> -->
      <el-table-column
        prop="timeShould"
        label="借款日期"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope">{{
            scope.row.timeShould | minifyDate
          }}</template>
      </el-table-column>
      <el-table-column
        prop="timeShould"
        label="放款日期"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope">{{
            scope.row.timeLoan | minifyDate
          }}</template>
      </el-table-column>
      <el-table-column
        prop="timeLimit"
        label="应还日期"
        align="center"
        min-width="120"
      >
        <templae slot-scope="scope">
          <span :class="scope.row.timeLimit | timeLimitToColor">{{
            scope.row.timeLimit | toDate | minifyDate
          }}</span>
        </templae>
      </el-table-column>
      <el-table-column prop="amount" label="应还金额(元)" align="center">
        <template slot-scope="scope">{{
          scope.row.amount / 100 ? scope.row.amount / 100 : ""
        }}</template>
      </el-table-column>

      <el-table-column
        prop="stagingPosition"
        label="期数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stagingDay"
        label="申请时长(天)"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderCount"
        label="笔数"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="overdueDay" label="延期" align="center"></el-table-column> -->
      <el-table-column
        prop="auditor"
        label="初审客服"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="finance"
        label="复审客服"
        align="center"
      ></el-table-column>
<!--      <el-table-column-->
<!--        prop="label"-->
<!--        label="备注"-->
<!--        align="center"-->
<!--      ></el-table-column>-->
      <el-table-column label="操作" align="center" min-width="130">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            circle
            @click="$emit('view', scope.row, false)"
          ></el-button>
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click.native.prevent="handleStag(scope.$index, scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            @click="$emit('delete', scope.row)"
          >备注</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 展示详情 -->
    <stagings
      :is-show="showstag"
      :edit-data="editData"
      @cancel="closeStag"
      @submit="submitStag"
    ></stagings>
  </div>
</template>

<script>
import stagings from "@/views/order/unpaid/components/stagings";
import dayjs from "dayjs";

export default {
  components: { stagings },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    //拿一下currentPage的值
    currentPage: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      showstag: false,
      editData: {}
    };
  },
  methods: {
    handleStag(index, row) {
      this.showstag = true;
      this.editData = row;
    },
    submitStag() {
      this.afterSubmit({ pageNum: this.currentPage });
    },
    afterSubmit(refreshParam) {
      this.showstag = false;
      // this.$notify({ type: 'success', message: '操作成功！' });
      this.$parent.getList({ pageNum: this.currentPage });
    },
    //关闭进行刷新
    closeStag(){
      this.showstag = false;
      this.$parent.getList({ pageNum: this.currentPage });
    }
  },
  filters: {
    toDate(d) {
      return dayjs(d).format("YYYY-MM-DD");
    },
    timeLimitToColor(timeLimit) {
      const isOverdue =
        dayjs(timeLimit).format("YYYY-MM-DD") <
        dayjs(new Date()).format("9YYYY-MM-DD");
      return isOverdue ? "is-overdue" : "";
    },
    minifyDate(d) {
      const now = new Date();
      if (typeof d == "string") {
        d = new Date(d);
      }
      let l = [];
      if (d.getFullYear() !== now.getFullYear()) {
        l.push(d.getFullYear());
      }
      l.push((d.getMonth() + 1 + "").padStart(2, "0"));
      l.push((d.getDate() + "").padStart(2, "0"));

      return l.join("-");
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
  .is-overdue {
    color: red;
  }
}
</style>
